import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import { TripPagePropsType, lang, RawTrip, RawTripItinerary } from '../../entities/queriesTypes';
import { useGlobalStateContext } from '../../Context/site';
import Layout from "../../components/template/Layout";
import Image from "../../components/atoms/Image";
import translations from "../../entities/translations";
import Wrapper from '../../components/template/ContainerMax';
import useSetGlobalState from '../../components/hooks/useSetGlobalState';
import GalleryCarousel from "../../components/molecule/Glidejs/Gallery";
import Text from "../../components/atoms/Text";
import { CurrentView } from '../../entities/templates';
import { buildImageFocalPoint, formatMoney, getCountryPageTranslation as gCPT, } from '../../utils';
import Accordion from "../../components/molecule/accordion";
import Calender from '../../components/svg/Calendar';
import Button from "../../components/atoms/Button"
import TravelBag from "../../components/svg/TravelBagIcon"
import PageSection from '../../components/organism/HomePageComponent';
import { Card } from '../../components/molecule/Card';
import { tripsSubMenu } from '../../utils/subNavigation'
import { useCartContext } from '../../Context/CartContext';
import { CardProps } from '../../components/molecule/Card/interface';
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";


const Index: React.FC<TripPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  page.parentView = 'trip';
  const TripsLinks = translations.globals.subMenuTripTranslation;

  const locale = pageContext && pageContext.locale
  const { state  } = useGlobalStateContext();
  const [countryTrips, setCountryTrips] = useState<RawTrip [] | undefined>(undefined);
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView["specific-trip"], parentView: 'subviews',
  subNavigationItem: tripsSubMenu })
  const pageID = translations.globals.subMenuTranslation;
  const buttonTransaltion = translations.globals.buttons;
  const _tripTranslation = translations.CountryPages.find((item) => item.menu === 'Reisen')!;
  const { Tag } = translations.globals.subMenuTripTranslation;
  const _accomodationTranslation = translations.CountryPages.find((item) => item.menu === 'Unterkunfte')!;
  const pageHash = translations.countryMenuID;
  const FROM = translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];
  const addToTravelBag = translations.globals.subMenuTripTranslation['Zur Reisetasche hinzufügen']
  const removeFRomTravelBag = translations.globals.subMenuTripTranslation['Aus Reisetasche entfernen']
  const { onCartClick, isInCart } = useCartContext()

  const cardCartProps = {
    uniqueRef: page?.['id']!,
    _className: "mx-2 sm:w-full md:w-full lg:w-full mb-4",
    imageAlt: (page?.tripImage?.alt && page?.tripImage?.alt[state?.lang as lang]) || '',
    imageUrl: buildImageFocalPoint(page.tripImage?.image),
    title: (page?.title && page?.title[state?.lang as lang]) || '',
    ctaText: buttonTransaltion.WEITERLESEN[state?.lang as lang] || '',
    cardType: "PACKAGES",
    ctaUrl: `/${state?.lang}/${page?.parentCountry?.slug &&
      page?.parentCountry?.slug[state?.lang as lang]?.current}/${_tripTranslation.slug[state?.lang as lang]}/${page?.slug
        && page?.slug[state?.lang as lang]?.current}#${pageHash?.Reisen?.[state?.lang as lang]!}`,
    bodyText: (page?.cardTextShort && page.cardTextShort[state?.lang as lang]) || '',
    duration: (page?.daysAndNightsShort && page.daysAndNightsShort[state?.lang as lang]) || '',
    price: `${FROM} ${(page?.priceCategoryFrom && formatMoney(page.priceCategoryFrom, page.priceCategoryCurrency))}` || '',
    cardSize: 'SMALL',
  }

  const cardInCart = isInCart && isInCart(cardCartProps?.uniqueRef);

  const getTitle = ( item : RawTripItinerary) => {
    return (
    <>
        <div className="flex">
        <Calender value={item?.startDay!} />
        <Text type="H3" className="mb-0 ml-4 text-dove-gray leading-7">
          {`${Tag?.[state?.lang as lang]} ${item?.startDay || ''} ${item?.endDay ? '- '+ item?.endDay || '' : ''} : ${item?.title?.[state?.lang as lang] || ''}`}
        </Text>
      </div>
    </>)
  }

  const getChildren = (item : RawTripItinerary, id?: string) => {

    return (
    <div>
      {item?.introText?.[state?.lang as lang] ?
        <PortableTextRenderer body={item?.introText?.[state?.lang as lang]} />
        : ''}

      {item?.tripItineraryGallery?.pictures?.length &&
        <div className="lg:w-10/12 my-10">
          <GalleryCarousel initClass={`specificRegionGallery_move_${id}`}>
            {item.tripItineraryGallery?.pictures?.map((item, i: number) => (
              <Image alt={(item?.alt && item?.alt[state?.lang as lang]) as string}
              src={buildImageFocalPoint(item?.image?.asset) as string}
              key={i} className="glide__slide object-cover w-full lg:h-large" />
            ))}
          </GalleryCarousel>
        </div>}

        {item?.outroText?.[state?.lang as lang] ?
          <PortableTextRenderer body={item?.outroText?.[state?.lang as lang]} />
           : ''}

        {
          item?.accommodation?.length ? (
          <div className="grid lg:grid-cols-2 gap-8">
            {item?.accommodation?.map((item, i) => <Card
            uniqueRef={item?.['_id']!}
              key={i}
              _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
              imageAlt={(item?.accommodationImage?.alt && item?.accommodationImage.alt[state?.lang as lang]) || ''}
                  imageUrl={buildImageFocalPoint(item?.accommodationImage?.image)}
                  title={(item?.title && item?.title[state?.lang as lang]) || ''}
                  ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
                  cardType="ACCOMODATION"
                  showEuroIcon={false}
                  ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug &&
                    item?.parentCountry?.slug[state?.lang as lang]?.current}/${_accomodationTranslation.slug[state?.lang as lang]}/${item?.slug
                      && item?.slug[state?.lang as lang]?.current}`}
                  location={''}
                  cardSize={'SMALL'}
              />)}
          </div>)
          :
          <></>
        }
    </div>
  )
  }

  useEffect(() => {
    if (page.parentCountry?.trips?.length) {
      const otherTrips = page.parentCountry?.trips.filter(item => item._id !== page.id);
      const _trips = [...otherTrips];
      if (_trips.length < 3) {
        setCountryTrips(_trips)
      } else {
        _trips.length = 3
        setCountryTrips(_trips)
      }
    }
  }, [page.id, page.parentCountry?.trips])

  return (
    <Layout seoTitle={'Specific Accommodation'} meta={[]}>
      <Wrapper className="mt-10">
      <div id={TripsLinks.Uberblick[state?.lang as lang]}>
        <Text type="H3" className="lg:w-8/12 mb-2" >{TripsLinks.Uberblick[state?.lang as lang]}</Text>
        
        <Text type="P" ptype="BASE" className="font-normal lg:w-8/12 text-dove-gray leading-7 mb-2" >
          {`${TripsLinks.Ab[state?.lang as lang]} ${formatMoney(page?.priceCategoryFrom || '', page?.priceCategoryCurrency)} `}
          <span className='pl-4'> 
            {page?.daysAndNightsShort?.[state?.lang as lang] || ''}
          </span>
        </Text>

        {page?.upperText?.[state?.lang as lang]
            && <PortableTextRenderer
            body={page?.upperText?.[state?.lang as lang]}
            blockquoteClassName="m-0 leading-11 text-sm font-light text-orange-red"
            />}

        {page?.tripGallery?.pictures?.length ?
          <div className="lg:w-10/12 my-10">
            <GalleryCarousel initClass='specificRegionGallery-Pictures'>
              {page.tripGallery?.pictures?.map((item, i: number) => (
                <Image alt={(item?.alt && item?.alt[state?.lang as lang]) as string}
                src={buildImageFocalPoint(item?.image?.asset) as string}
                key={i}
                className="glide__slide object-cover w-full lg:h-large" />
              ))}
            </GalleryCarousel>
          </div>: ''}

          <div>
            <div className="grid lg:w-7/12 my-10">
              {false && page.tripSummaryItinerary?.map((item, i) => (
                <div key={i} className="grid grid-cols-4 gap-x-4 border-b border-gray-400">
                  <div className=" text-base text-blue-300 row-span-4">{item?.title?.[state?.lang as lang]}</div>
                  <div className="col-span-3">
                    {(item?.content?.[state?.lang as lang]) &&
                    <PortableTextRenderer body={item?.content?.[state?.lang as lang]}
                      className="lg:w-8/12 border-b py-1"
                      normalClassName="text-sm mb-0 text-dove-gray"/>}

                    {(item?.content_two?.[state?.lang as lang]) &&
                    <PortableTextRenderer body={item?.content_two?.[state?.lang as lang]}
                      className="lg:w-8/12 border-b py-1"
                      normalClassName="text-sm mb-0 text-dove-gray"/>}

                    {(item?.content_three?.[state?.lang as lang]) &&
                    <PortableTextRenderer body={item?.content_three?.[state?.lang as lang]}
                      className="lg:w-8/12 border-b py-1"
                      normalClassName="text-sm mb-0 text-dove-gray"/>}

                    {(item?.content_four?.[state?.lang as lang]) &&
                    <PortableTextRenderer body={item?.content_four?.[state?.lang as lang]}
                      className="lg:w-8/12 border-b py-1"
                      normalClassName="text-sm mb-0 text-dove-gray"/>}
                  </div>
                </div>
              ))}
            </div>
          </div>

      </div>
      </Wrapper>
      {page?.tripMapImage ?
          <div style={{ background: `url(${buildImageFocalPoint(page?.tripMapImage?.image?.asset) as string})` }} className="h-large" >
          </div>
        : ''}

      <Wrapper className="my-8">
        <div id={TripsLinks.Tourverlauf[state?.lang as lang]}>
          <Text type="H3" className="mb-4" >{TripsLinks.Tourverlauf[state?.lang as lang]}</Text>
          {page?.tripItineraryIntro?.[state?.lang as lang]
            && (
              <div className='mb-6'>
                <PortableTextRenderer body={page?.tripItineraryIntro?.[state?.lang as lang]}
                normalClassName="text-sm text-dove-gray" />
              </div>
            )
            }

          {page?.tripItinerary?.length ?
            <Accordion accordionRecord={page?.tripItinerary} getChildren={getChildren} getTitle={getTitle} /> : <></>
          }
        </div>

        <div id={TripsLinks.Leistungen[state?.lang as lang]} className="mt-12">
          <Text type="H3" className="" >{TripsLinks.Leistungen[state?.lang as lang]}</Text>

          {page?.tripServices?.[state?.lang as lang]
            && <PortableTextRenderer body={page?.tripServices?.[state?.lang as lang]} /> }
        </div>

        <div id={TripsLinks['Leistungen ausgeschlossen'][state?.lang as lang]} className="mt-12">
          <Text type="H3" className="" >{TripsLinks['Leistungen ausgeschlossen'][state?.lang as lang]}</Text>

          {page?.excludedTripServices?.[state?.lang as lang]
            && <PortableTextRenderer body={page?.excludedTripServices?.[state?.lang as lang]} /> }
        </div>

        <div id={TripsLinks.Preise[state?.lang as lang]} className="mt-12">
            <Text type="H3" className="" >{TripsLinks['Preise und Daten'][state?.lang as lang]}</Text>
            <div className="grid lg:w-7/12 my-10">
                {page.tripPricingDatesInfo?.map((item, i) => (
                  <div key={i} className="grid grid-cols-4 gap-x-8 border-b border-gray-400">
                    <div className=" text-base text-blue-300">{item?.title && item?.title[state?.lang as lang]}</div>
                    {(item?.content?.[state?.lang as lang]) &&
                    <PortableTextRenderer className="lg:w-8/12 col-span-3" body={item?.content?.[state?.lang as lang]}
                    normalClassName="text-sm mb-0 text-dove-gray" /> }
                  </div>
                ))}
              </div>
          </div>
          <div className="mt-10">
            <Button onClick={() => onCartClick && onCartClick(cardCartProps as CardProps)} size="btn-base" color="btn-primary" className={`flex justify-between items-center py-0 pr-12 ${cardInCart ? "bg-red-600" : ""}`}>
              <div className="transform scale-75"><TravelBag fill="#FFFFFF" /></div>
              {cardInCart ? removeFRomTravelBag?.[state?.lang as lang] : addToTravelBag?.[state?.lang as lang]}
            </Button>
          </div>
      </Wrapper>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.allSlugs?.country?.[state?.lang as lang]?.current}/${gCPT
            ({ view: CurrentView['specific-trip'], lang: state?.lang as lang })}#${pageHash?.Reisen?.[state?.lang as lang]!}`}
        sanityContext={null}
        id={`${pageHash?.Reisen?.[state?.lang as lang]!}`}
        layerBackground="bg-white-smoke"
        showReadMore={true}
        idTitle={pageID.Reisen[state?.lang as lang]!}
        title={state?.countryName!} >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
         {countryTrips?.map((item, i: number) => <Card
          uniqueRef={item?.['_id']!}
          key={i}
          _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
          imageAlt={(item?.tripImage?.alt && item?.tripImage?.alt[state?.lang as lang]) || ''}
          imageUrl={buildImageFocalPoint(item.tripImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang]) || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="PACKAGES"
          ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug &&
            item?.parentCountry?.slug[state?.lang as lang]?.current}/${_tripTranslation.slug[state?.lang as lang]}/${item?.slug
              && item?.slug[state?.lang as lang]?.current}#`}
          bodyText={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
          duration={(item?.daysAndNightsShort && item.daysAndNightsShort[state?.lang as lang]) || ''}
          price={`${FROM} ${(formatMoney(item.priceCategoryFrom || '', item.priceCategoryCurrency))}` || ''}
          cardSize={'SMALL'}
          /> )}
        </div>
      </PageSection>
    </Layout>
  )
};

export default Index;


export const query = graphql`
  query tripIndexPage($id: String!) {
    pageData: sanityTrip(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle
      heroImage: _rawTripImage(resolveReferences: {maxDepth: 4})
      cardTextShort:_rawCardTextShort
      tripImage: _rawTripImage
      daysAndNightsShort: _rawDaysAndNightsShort
      priceCategoryFrom
      priceCategoryCurrency
      parentCountry {
        title: _rawTitle
        slug :_rawSlug
        trips {
          _id
          title: _rawTitle
          tripImage: _rawTripImage
          slug :_rawSlug
          cardTextShort:_rawCardTextShort
          daysAndNightsShort: _rawDaysAndNightsShort
          priceCategoryFrom
          priceCategoryCurrency
          parentCountry {
            slug :_rawSlug
          }
        }
      }
      tripPricingDatesInfo: _rawTripPricingDatesInfo(resolveReferences: {maxDepth: 1})
      tripPricingDates : _rawTripPricingDates(resolveReferences: {maxDepth: 1})
      tripItineraryIntro: _rawTripItineraryIntro(resolveReferences: {maxDepth: 4})
      upperText: _rawUpperText
      tripGallery: _rawTripGallery(resolveReferences: {maxDepth: 10})
      tripSummaryItinerary:_rawTripSummaryItinerary(resolveReferences: {maxDepth: 4})
      tripMapImage:_rawTripMapImage(resolveReferences: {maxDepth: 4})
      tripItinerary {
        introText: _rawIntroText
        outroText: _rawOutroText
        tripItineraryGallery: _rawTripItineraryGallery(resolveReferences: {maxDepth: 3})
        accommodation {
          _id
          _key
          title {
            de
           
          }
          parentCountry {
            _id
            slug {
              de {
                current
              }

            }
          }
          slug {
            de {
              current
            }

          }
          accommodationImage: _rawAccommodationImage(resolveReferences: {maxDepth: 3})
        }
        _key
        title {
          de
         
        }
        startDay
        endDay
      }
      tripServices:_rawTripServices(resolveReferences: {maxDepth: 4}),
      excludedTripServices: _rawExcludedTripServices(resolveReferences: {maxDepth: 4}),
    }
  }
`
