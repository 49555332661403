import React from 'react'

const Calender: React.FC<{ value : number }> = ({ value }) => {
  return (
    <svg width="33px" height="33px" viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/Calendar-font</title>
      <g id="icon/Calendar-font" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="calendar-page-on-day-2" fill="#8C7E64" fillRule="nonzero">
              <path d="M28.5,0 L4.5,0 C2.019,0 0,2.019 0,4.5 L0,28.5 C0,30.98 2.019,33 4.5,33 L28.5,33 C30.98,33 33,30.98 33,28.5 L33,4.5 C33,2.019 30.98,0 28.5,0 Z M31.5,28.5 C31.5,30.148 30.15,31.5 28.5,31.5 L4.5,31.5 C2.85,31.5 1.5,30.148 1.5,28.5 L1.5,4.5 C1.5,2.851 2.85,1.5 4.5,1.5 L28.5,1.5 C30.15,1.5 31.5,2.851 31.5,4.5 L31.5,28.5 Z" id="Shape"></path>
              <path d="M24.318,3.5 L8.681,3.5 C8.294,3.5 7.976,3.817 7.976,4.205 L7.976,5.342 C7.976,5.73 8.293,6.047 8.681,6.047 L24.318,6.047 C24.706,6.047 25.023,5.73 25.023,5.342 L25.023,4.205 C25.023,3.817 24.706,3.5 24.318,3.5 Z" id="Path"></path>
          </g>
          <text id="1" fontFamily="Montserrat-Medium, Montserrat" fontSize="18" fontWeight="400" fill="#8C7E64">
              <tspan x="14.17" y="22">{value}</tspan>
          </text>
      </g>
    </svg>
  )
}

export default Calender;
