import React, { useState } from 'react';
import DownArrowSand from '../../svg/DownArrowSand';
import UpArrowSand from '../../svg/UpArrowSand';
interface IAccordion {
  accordionRecord: any [];
  getChildren: (inp: any, i?: string) => any;
  getTitle: (inp: any) => any;
  customClasses?: string;
}

const Accordion: React.FC<IAccordion> = ({ accordionRecord, getChildren, getTitle, customClasses}) => {

  const [openArticle, setopenArticle] = useState<number|undefined>(0)
  return (
    <section>
      {accordionRecord.map(((item, i) => (
        <article key={i} className={`bg-white shadow-fullSides lg:w-7/12 py-5 px-5 lg:pl-8 lg:pr-0 mb-5 overflow-hidden ${customClasses || ''}`} 
        >
          <div className="lg:w-11/12">
            <div className="flex justify-between items-center">
              {getTitle(item)}
              <div className="cursor-pointer" onClick={() => {
                    if(openArticle === i) {
                      setopenArticle(undefined)
                    } else {
                      setopenArticle(i)
                    }
              }}>
                {openArticle === i ? <UpArrowSand /> : <DownArrowSand />}
              </div>
            </div>
            {openArticle === i && (
              <div className="my-4">
                <div className="animate__animated animate__slideInUp animate__faster" id={`menu-${i}`}>
                  {getChildren(item, String(i))}
                </div>
              </div>
            )}
          </div>
        </article>
      )))}
    </section>
  )
}

export default Accordion;
